export function concatArray(obj,key,maxRes=3){
    let result = ""
    let cant = 0
    obj.forEach(e => {
      if(cant === maxRes){
        result += ", otros"
        return result.substring(2,result.length)
      }
      result += ", "+e[key]
      cant ++
    })
    return result.substring(2,result.length)
  }

export default concatArray;