import React, {useEffect,useState} from 'react';
import MaterialTable from "@material-table/core";


// import './assets/css/Monitor.css'

import { format } from "date-fns";
import  concatArray from '../assets/js/utils.js'
const rowBackgroundColors = {
  "4": "#1F618D",// en Realizada (Azul)
  "2": "#f4d03f ", //demorada ( AMARILLO)
  "3" :"#CC0033",// Suspendida
  "1" :"#abebc6" // Horario  
  };
const rowColors = {
  "1": "#000", // en horario (Celeste)
  "2": "#000", //demorada ( AMARILLO)
  "3" :"#FFF",// Suspendida
  "4" :"#FFF" // Realizada 
};

  function fixData(resultSet){
    // console.log(resultSet)
    let newData = []
    resultSet.forEach(e => {
      if(e.sala.includes("juvenil") || e.sala.includes("Juvenil")){
        console.log(e.sala)
        return
      }
      let obj = {}
      obj.hora_audiencia = e.hora_audiencia
      obj.sala = e.sala
      obj.nro_causa = e.nro_causa
      obj.caratula = e.caratula
      obj.estado = e.estado
      obj.juez = concatArray(e.juez,"nombre",3)
      obj.fiscal = concatArray(e.fiscal,"nombre",3)
      obj.defensor = concatArray(e.defensor,"nombre",3)

      newData.push(obj)
    });
    return newData
  }

  function Monitor(){
    let title =
    <div className="App"><h2> INFORMACIÓN DE AUDIENCIAS PENALES: Fecha: {format(new Date(), "dd-MMM-yyyy")} </h2> <span>
      <h3>
    
         - En horario:<font color="#abebc6"> &#9608;</font> Demorada:<font color="#f4d03f"> &#9608;</font> Realizada:<font color="#1F618D"> &#9608;</font>   Suspendida:<font color="#CC0033"> &#9608;</font>   
         </h3> </span>
     </div>
     
    
    const [audiencias,setAudiencias] = useState()
    const [page,setPage] = useState(1)
    
    useEffect(() => {
      const getData = async () => {
        let days = 0
      const urlApi = process.env.REACT_APP_URL_BACKEND_MONITOR+"/"+days+"/"+page
             console.log("API URL: "+urlApi)
        const response = await fetch(urlApi)
        const responseJson = await response.json()
        
        console.log("CONSULTANDO PAGINA: "+page+" ROWS: "+responseJson.data.length)
   
        if(responseJson.data.length) setAudiencias(fixData(responseJson.data))
        else setPage(1)
      }
      const timeOut = setTimeout(() => {
        setPage(page+1)
      }, 10000);
      getData()
      return () => {
        clearTimeout(timeOut);
      };   
     }, [page])   

     
    return(
      <React.Fragment>
        { !audiencias ? 'Cargando ... ' :
          
          <MaterialTable

          title = {title}
      
          columns={[
            
             
          
            { title: "HORA", field: "hora_audiencia",width: 100, 
            cellStyle: {fontWeight: '1000'},

            headerStyle: {
            backgroundColor: "#039be5",
            }
           
                      
          },
                     
          { title: "SALA", field: "sala",width: 200, cellStyle: {fontWeight: 'bold'}},
            { title: "CAUSA", field: "nro_causa",width: 150},
            { title: "CARATULA", field: "caratula" },
            { title: "JUEZ", field: "juez" },
            { title: "FISCAL", field: "fiscal" },
            { title: "DEFENSOR", field: "defensor" },
                 
          
          ]}
          options={{
            
            paging: false,
            search: false,
            rowStyle: (rowData) => {
              return {
                fontFamily: "ARIAL",
                color: rowColors[rowData.estado] ?? "#55ddf0",
                backgroundColor: rowBackgroundColors[rowData.estado] ?? "#55ddf0",
                border: '5', 
                cellStyle: {fontSize: '11px'},
              };
            },
          
            headerStyle: {
              backgroundColor: "#000",
              color: "#FFF",
                  cellStyle: {
                  backgroundColor: "#6ABAC9",
                          },
            },
          }}
            data={audiencias}
           
          />
          
        }
      </React.Fragment>
    )
    
  }


export default Monitor;
